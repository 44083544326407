// Override CSS

$light: #eff2fc;
$primary: rgba(15, 94, 247, 1);

*:focus {
    outline: none !important;
    box-shadow: none !important;
}

.bg-light,
.aui-navgroup-vertical .aui-nav .aui-nav-selected,
.aui-sidebar .aui-navgroup-vertical .aui-nav .aui-nav-selected > .aui-nav-item {
    background-color: $light !important;
}

.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li {
    padding: 10px;
}

.aui-sidebar[aria-expanded="false"]
    .aui-sidebar-group-tier-one
    .aui-nav
    > li.aui-nav-selected
    > .aui-nav-item
    > .aui-icon {
    color: $primary !important;
}

.text-bold {
    font-weight: 600;
}

.aui-page-panel {
    background-color: transparent;
    z-index: 1;
}

canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
}

.text-auto-align {
    text-wrap: auto;
}

.login-form {
    width: 75% !important;
    padding: 50px 40px !important;
}

.event-form {
    width: 100% !important;
    padding: 50px 40px !important;
}

@media (max-width: 770px) {
    canvas {
        z-index: -1;
    }
    .hide-on-mobile {
        display: none;
    }

    .login-form {
        zoom: 100% !important;
        width: 100% !important;
    }
}

.audio {
    width: 100%;
    height: 60px;
    background-color: #f1f3f4;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.upload-file-name {
    margin: 6px;
    padding: 6px 20px;
    width: auto;
    border-radius: 5px;
    background-color: rgba(201, 201, 201, 0.446);
}

.rm-audio-player-provider * div.btn-wrapper {
    display: flex;
}